import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { Project, ProjectTicket } from "../Pages/SubPages/Developer";
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from "react-bootstrap";
import {allUsers} from "../TempData/TempData"
interface Props {
    project: Project
    onTicketCreate: (ticket: ProjectTicket, project: Project) => void
    saveChange: (project: Project) => void
}

const DragContainer = ({ project, onTicketCreate, saveChange }: Props) => {
    const lists = useMemo(() => ["Queued", "In Progress", "Testing", "Completed"], []);

    const [elements, setElements] = useState<any>({
        Queued: [],
        "In Progress": [],
        Testing: [],
        Completed: []
    });
    const [selectedPrefix, setSelectedPrefix] = useState<string>("");
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [selectedUser, setSelectedUser] = useState<string>("")
    const [priority, setPriority] = useState<string>("")

    const [editTitle, setEditTitle] = useState<string>("")
    const [editDescription, setEditDescription] = useState<string>("")
    const [editSelectedUser, setEditSelectedUser] = useState<string>("")
    const [editPriority, setEditPriority] = useState<string>("")
    const [selectedTicket, setSelectedTicket] = useState<ProjectTicket>()
    const [department, setDepartment] = useState<string>("")
    const [timeSpent, setTimeSpent] = useState<string>("")
    const [timeType, setTimeType] = useState<string>("hours")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEditClose = () => setShowEdit(false);
    const handleEditShow = () => setShowEdit(true);

    const removeFromList = (list: ProjectTicket[], index: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    };

    const addToList = useCallback((list: ProjectTicket[], index: number, element: any) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    }, []);

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const listCopy: typeof elements = { ...elements };
            const sourceList = listCopy?.[result.source.droppableId];

            const [removedElement, newSourceList] = removeFromList(
                sourceList,
                result.source.index
            );
            listCopy[result.source.droppableId] = newSourceList;
            const destinationList = listCopy[result.destination.droppableId];
            listCopy[result.destination.droppableId] = addToList(
                destinationList,
                result.destination.index,
                removedElement
            );
            setElements(listCopy);
            const temp = project
            temp.tickets = listCopy
            saveChange(temp)
        },
        [elements, addToList]
    );

    const submit = () => {
        const user = allUsers.filter((user) => user.id === selectedUser)
        let tempUser = {
            email: "",
            id: "",
            name: ""
        }
        if(selectedUser === "1101") {
            tempUser = {
                email: "",
                id: "",
                name: "1101"
            }
        } else {
            tempUser = {
                email: user[0].mail,
                id: user[0].id,
                name: user[0].displayName
            }
        }
        let highest = 0
        if(project.tickets.Queued.length > 0) {
            project.tickets.Queued.map((item) => {
                if(parseInt(item.id.split("-")[1]) > highest) {
                    highest = parseInt(item.id.split("-")[1])
                }
            })
        }
        if(project.tickets["In Progress"].length > 0) {
            project.tickets["In Progress"].map((item) => {
                if(parseInt(item.id.split("-")[1]) > highest) {
                    highest = parseInt(item.id.split("-")[1])
                }
            })
        }
        if(project.tickets.Testing.length > 0) {
            project.tickets.Testing.map((item) => {
                if(parseInt(item.id.split("-")[1]) > highest) {
                    highest = parseInt(item.id.split("-")[1])
                }
            })
        }
        if(project.tickets.Completed.length > 0) {
            project.tickets.Completed.map((item) => {
                if(parseInt(item.id.split("-")[1]) > highest) {
                    highest = parseInt(item.id.split("-")[1])
                }
            })
        }
        const temp: ProjectTicket = {
            id: project.id + "-" + (highest + 1),
            description: description,
            dateRaised: new Date().toString(),
            title: title,
            priority: priority,
            prefix: selectedPrefix,
            user: tempUser
        }
        onTicketCreate(temp, project)
    }

    const deleteTicket = () => {
        if(selectedTicket) {
            const tempQ = project.tickets.Queued.filter((a: ProjectTicket) => a.id !== selectedTicket.id)
            const tempP = project.tickets["In Progress"].filter((a: ProjectTicket) => a.id !== selectedTicket.id)
            const tempT = project.tickets.Testing.filter((a: ProjectTicket) => a.id !== selectedTicket.id)
            const tempC = project.tickets.Completed.filter((a: ProjectTicket) => a.id !== selectedTicket.id)
            const temp: Project = {
                id: project.id,
                projectName: project.projectName,
                status: project.status,
                tickets: {
                    Queued: tempQ,
                    "In Progress": tempP,
                    Testing: tempT,
                    Completed: tempC
                }
            }
            saveChange(temp)
        }
    }

    const editTicket = () => {
        const user = allUsers.filter((user) => user.id === editSelectedUser)
        let tempUser = {
            email: "",
            id: "",
            name: ""
        }
        if(editSelectedUser === "1101") {
            tempUser = {
                email: "",
                id: "",
                name: "1101"
            }
        } else {
            tempUser = {
                email: user[0].mail,
                id: user[0].id,
                name: user[0].displayName
            }
        }
        if(selectedTicket) {
            const tempQ = project.tickets.Queued.map((a: ProjectTicket) => {
                if(a.id === selectedTicket.id) {
                    a.description = editDescription
                    a.title = editTitle
                    a.user = tempUser
                    a.priority = editPriority
                    a.department = department
                    a.timeSpent = timeSpent === "" ? "" : `${timeSpent}-${timeType}`
                }
                return a
            })
            const tempP = project.tickets["In Progress"].map((a: ProjectTicket) => {
                if(a.id === selectedTicket.id) {
                    a.description = editDescription
                    a.title = editTitle
                    a.user = tempUser
                    a.priority = editPriority
                    a.department = department
                    a.timeSpent = timeSpent === "" ? "" : `${timeSpent}-${timeType}`
                }
                return a
            })
            const tempT = project.tickets.Testing.map((a: ProjectTicket) => {
                if(a.id === selectedTicket.id) {
                    a.description = editDescription
                    a.title = editTitle
                    a.user = tempUser
                    a.priority = editPriority
                    a.department = department
                    a.timeSpent = timeSpent === "" ? "" : `${timeSpent}-${timeType}`                
                }
                return a
            })
            const tempC = project.tickets.Completed.map((a: ProjectTicket) => {
                if(a.id === selectedTicket.id) {
                    a.description = editDescription
                    a.title = editTitle
                    a.user = tempUser
                    a.priority = editPriority
                    a.department = department
                    a.timeSpent = timeSpent === "" ? "" : `${timeSpent}-${timeType}`
                }
                return a
            })
            const temp: Project = {
                id: project.id,
                projectName: project.projectName,
                status: project.status,
                tickets: {
                    Queued: tempQ,
                    "In Progress": tempP,
                    Testing: tempT,
                    Completed: tempC
                }
            }
            saveChange(temp)
        }
    }

    useEffect(() => {
        setElements(project.tickets)
    }, [project])

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="list-grid">
                <Modal data-bs-theme="dark" size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add ticket to {selectedPrefix.toLowerCase()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Summary:</Form.Label>
                            <Form.Control value={title} onChange={(e) => setTitle(e.currentTarget.value)} placeholder="Enter summary..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control value={description} onChange={(e) => setDescription(e.currentTarget.value)} placeholder="Enter description..." as="textarea" rows={4} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Priority:</Form.Label>
                            <Form.Select value={priority} onChange={(e) => setPriority(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select a priority...</option>
                                <option value="Low Priority">Low Priority</option>
                                <option value="Medium Priority">Medium Priority</option>
                                <option value="High Priority">High Priority</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Assign user:</Form.Label>
                            <Form.Select value={selectedUser} onChange={(e) => setSelectedUser(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select a user...</option>
                                <option value="9a5a2200-baab-4afd-9ccc-3507cdae153d">Ben Mallett</option>
                                <option value="5b6600c9-e466-4f3f-8538-76da45fe82a8">Callie Luck</option>
                                <option value="f28ab930-a6da-4de2-a9f1-6c74377445b7">Elliot Bonser</option>
                                <option value="aef677be-f5b3-4c3a-9050-05bb8709b48c">Iain Brown</option>
                                <option value="761b869d-2f90-4be7-91a6-3aaee6df11cd">Lewis Toseland</option>
                                <option value="1101">1101</option>
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => {
                            submit()
                            setSelectedUser("")
                            setDescription("")
                            setTitle("")
                            setPriority("")
                            handleClose()
                            }} className="buttonES">Raise ticket</Button>
                    </Modal.Footer>
                </Modal>
                <Modal data-bs-theme="dark" size="lg" show={showEdit} onHide={handleEditClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Summary:</Form.Label>
                            <Form.Control value={editTitle} onChange={(e) => setEditTitle(e.currentTarget.value)} placeholder="Enter summary..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control value={editDescription} onChange={(e) => setEditDescription(e.currentTarget.value)} placeholder="Enter description..." as="textarea" rows={4} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Priority:</Form.Label>
                            <Form.Select value={editPriority} onChange={(e) => setEditPriority(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select a priority...</option>
                                <option value="Low Priority">Low Priority</option>
                                <option value="Medium Priority">Medium Priority</option>
                                <option value="High Priority">High Priority</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Assign user:</Form.Label>
                            <Form.Select value={editSelectedUser} onChange={(e) => setEditSelectedUser(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select a user...</option>
                                <option value="9a5a2200-baab-4afd-9ccc-3507cdae153d">Ben Mallett</option>
                                <option value="5b6600c9-e466-4f3f-8538-76da45fe82a8">Callie Luck</option>
                                <option value="f28ab930-a6da-4de2-a9f1-6c74377445b7">Elliot Bonser</option>
                                <option value="aef677be-f5b3-4c3a-9050-05bb8709b48c">Iain Brown</option>
                                <option value="761b869d-2f90-4be7-91a6-3aaee6df11cd">Lewis Toseland</option>
                                <option value="1101">1101</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <p className="containerES-ticket-content__label">Department:</p>
                        <Form.Select value={department} onChange={(e) => setDepartment(e.currentTarget.value)}>
                            <option value="">Select department...</option>
                            <option value="Contracts - Office">Contracts - Office</option>
                            <option value="Contracts - Site">Contracts - Site</option>
                            <option value="Design">Design</option>
                            <option value="Directors">Directors</option>
                            <option value="Finance & Warehouse">Finance & Warehouse</option>
                            <option value="Process">Process</option>
                            <option value="Remedials - FPS">Remedials - FPS</option>
                            <option value="Remedials - LPS">Remedials - LPS</option>
                            <option value="Rope Access">Rope Access</option>
                            <option value="Sales">Sales</option>
                            <option value="Test & Inspection Operations">Test & Inspection Operations</option>
                            <option value="Test & Inspection Sales">Test & Inspection Sales</option>
                            <option value="Test & Inspection Site">Test & Inspection Site</option>
                            <option value="Training & PPE">Training & PPE</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                    <p className="containerES-ticket-content__label">Time spent:</p>
                    <div className="d-flex flex-row">
                    <Form.Control
                            value={timeSpent}
                            onChange={(value) => setTimeSpent(value.currentTarget.value)}
                            className="w-75 timeSpent"
                            type='Number'
                            placeholder={`Enter ${timeType} spent...`} />
                            <Form.Select className="w-25 timeType" value={timeType} onChange={(e) => setTimeType(e.currentTarget.value)}>
                            <option value="hours">Hours</option>
                            <option value="minutes">Minutes</option>
                        </Form.Select>
                    </div>
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={() => {
                            deleteTicket()
                            handleEditClose()
                            }} className="buttonES">Delete ticket</Button>
                        <Button onClick={() => {
                            editTicket()
                            handleEditClose()
                            }} className="buttonES">Save ticket</Button>
                    </Modal.Footer>
                </Modal>
                {lists.map((listKey) => (
                    <DraggableElement
                    onTicketClick={(ticket) => {
                        handleEditShow()
                        setSelectedTicket(ticket)
                        setEditDescription(ticket.description)
                        setEditSelectedUser(ticket.user?.id || "")
                        setEditTitle(ticket.title)
                        setEditPriority(ticket.priority || "")
                        setDepartment(ticket.department || "")
                        setTimeSpent(ticket.timeSpent?.split("-")[0] || "")
                        setTimeType(ticket.timeSpent ? capitalizeFirstLetter(ticket.timeSpent?.split("-")[1]) : "")
                    }}
                        elements={elements[listKey]}
                        key={listKey}
                        prefix={listKey}
                        addTicket={(prefix) => {
                            setSelectedPrefix(prefix)
                            handleShow()
                        }}
                    />
                ))}
            </div>
        </DragDropContext>
    )
}

export default DragContainer