import { User } from "../Pages/AuthenticatedHome";
import wave from "../Images/wave.png";
import Status from "./Status";
import { Button, Form } from "react-bootstrap";
import { FaExpand, FaX } from "react-icons/fa6";
import { useEffect, useState } from "react";
import moment from "moment";
import { ReportRequest } from "../Pages/SubPages/Reports";
import { FileUploader } from "react-drag-drop-files";
import Papa, { ParseResult, ParseError } from 'papaparse';
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

interface Props {
    children: React.ReactNode;
    reportRequest?: ReportRequest;
    user: User;
    admins: User[];
    viewRequest: boolean;
    closeRequest: () => void;
    statusChange: (status: string) => void;
    approvalRequestChange: (status: string) => void;
    approvalReportChange: (status: string) => void;
    setReportData: (data: any[], report: ReportRequest) => void;
}

const ReportContainer = ({ admins, setReportData, children, closeRequest, user, viewRequest, reportRequest, approvalReportChange, approvalRequestChange, statusChange }: Props) => {
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches;
    const [status, setStatus] = useState<string>("");
    const [requestApproval, setRequestApproval] = useState<string>("");
    const [ReportApproval, setReportApproval] = useState<string>("");
    const isAdmin = admins.find((e) => e.id === user.id);
    const isSuperAdmin = ["5b6600c9-e466-4f3f-8538-76da45fe82a8", "761b869d-2f90-4be7-91a6-3aaee6df11cd", "9a5a2200-baab-4afd-9ccc-3507cdae153d"].find((e) => e === user.id);
    const [file, setFile] = useState<any[]>([]);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const fileTypes = ["CSV"];

    const handleChange = (file: File) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const csvData = e.target?.result; // Explicitly cast to string
            //@ts-ignore
            Papa.parse(csvData, {
                header: true,  // This option will treat the first row as headers
                complete: (result: ParseResult<any>) => {
                    const jsonData = result.data;
                    if (reportRequest) {
                        setReportData(jsonData, reportRequest);
                    }
                    setFile(jsonData);
                },
                error: (error: ParseError) => {
                    console.error("Error parsing CSV:", error.message);
                }
            });
        };

        reader.readAsText(file);  // Read the file as text
    };

    // Disable copy/paste
    useEffect(() => {
        const handleCopy = (e: ClipboardEvent) => {
            e.preventDefault();
            alert("Copying is disabled. If you do not have an export button please contact Callie Luck.");
        };

        document.addEventListener('copy', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, []);

    // Disable right-click
    useEffect(() => {
        const handleContextMenu = (e: MouseEvent) => {
            e.preventDefault();
            alert("Copying is disabled. If you do not have an export button please contact Callie Luck.");
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {
        if (status !== reportRequest?.status) {
            statusChange(status);
        }
    }, [status]);

    useEffect(() => {
        if (requestApproval !== reportRequest?.RequestApproval) {
            approvalRequestChange(requestApproval);
        }
    }, [requestApproval]);

    useEffect(() => {
        if (ReportApproval !== reportRequest?.ReportApproval) {
            approvalReportChange(ReportApproval);
        }
    }, [ReportApproval]);

    useEffect(() => {
        if (reportRequest) {
            setStatus(reportRequest.status);
            setRequestApproval(reportRequest.RequestApproval);
            setReportApproval(reportRequest.ReportApproval);
            if (reportRequest.data.length > 0) {
                setFile(reportRequest.data);
            } else {
                setFile([])
            }
        }
    }, [reportRequest]);

    useEffect(() => {
        if (file.length > 0) {
            const col = Object.entries(file[0]).map(([key]) => {
                return { field: key, headerName: key.toUpperCase(), flex: 1 };
            });
            const r = file.map((a, index) => {
                return { ...a, id: index.toString() };
            });
            setColumns(col);
            setRows(r);
        }
    }, [file]);


    const downloadData = () => {
        // Check if the data is not empty
        if (rows.length === 0) {
            console.error('Empty data');
            return;
        }

        // Extract headers from the object keys
        const headers = Object.keys(rows[0]);

        // Convert array of objects to CSV string
        const csvContent = [
            headers.join(','), // header row
            ...rows.map(obj => headers.map(header => `"${obj[header]}"`).join(',')) // data rows
        ].join('\n');

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "ExportedReportData.csv";

        // Append the link, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    console.log(reportRequest)
    return (
        <div className="containerES">
            <div className={`containerES-children containerES-children--reports ${!viewRequest && "w-100"} ${fullScreen && "w-0"}`}>
                {children}
            </div>
            {viewRequest && reportRequest &&
                <div className={`containerES-ticket ${fullScreen && "w-100"}`}>
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div></div>
                            <div className="d-flex flex-row">
                                <FaExpand onClick={() => setFullScreen(!fullScreen)} className="containerES-ticket-content__close mr-2" color="white" size={24} />
                                <FaX onClick={closeRequest} className="containerES-ticket-content__close" color="black" size={24} />
                            </div>
                        </div>
                        <h2 className="containerES-ticket-content__title">{reportRequest.title}</h2>
                        <div className="containerES-ticket-content-row mb-0">
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Requested Date:</p>
                                <p className="containerES-ticket-content__value">{moment(reportRequest.requestedDate).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Due Date:</p>
                                <p className="containerES-ticket-content__value">{moment(reportRequest.dueDate).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Requester:</p>
                                <p className="containerES-ticket-content__value">{reportRequest.user.name}</p>
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Department:</p>
                                <p className="containerES-ticket-content__value">{reportRequest.department}</p>
                            </div>
                        </div>
                        <div className="containerES-ticket-content-row mb-3">
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                {isAdmin ? (
                                    <>
                                        <p className="containerES-ticket-content__label">Status:</p>
                                        <Form.Group controlId="adminStatusDropdown.change">
                                            <Form.Select disabled={reportRequest.RequestApproval === "Awaiting Approval"} value={status} onChange={(e) => setStatus(e.currentTarget.value)}>
                                                <option value={"Queued"}>Queued</option>
                                                <option value={"In Progress"}>In Progress</option>
                                                <option value={"Send For Approval"}>Send For Approval</option>
                                                <option value={"Denied"}>Denied</option>
                                                {(reportRequest.ReportApproval === "Approved" || reportRequest.ReportApproval === "Approved & Downloadable") &&
                                                    <option value={"Completed"}>Completed</option>
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </>
                                ) : (
                                    <>
                                        <p className="containerES-ticket-content__label">Status:</p>
                                        <Status text={reportRequest.status === "Send For Approval" ? "In Progress" : reportRequest.status} />
                                    </>
                                )}
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                {isSuperAdmin && reportRequest.RequestApproval !== "Approved" && (
                                    <>
                                        {isSuperAdmin ? (
                                            <>
                                                <p className="containerES-ticket-content__label">Request Status:</p>
                                                <Form.Group controlId="adminStatusDropdown.change">
                                                    <Form.Select value={requestApproval} onChange={(e) => setRequestApproval(e.currentTarget.value)}>
                                                        <option value={"Awaiting Approval"}>Awaiting Approval</option>
                                                        <option value={"Approved"}>Approved</option>
                                                        <option value={"Denied"}>Denied</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </>
                                        ) : (
                                            <>
                                                <p className="containerES-ticket-content__label">Request Status:</p>
                                                <Status text={reportRequest.RequestApproval} />
                                            </>
                                        )}
                                    </>
                                )}
                                {isSuperAdmin && (reportRequest.status === "Send For Approval" || reportRequest.status === "Completed") && (
                                    <>
                                        {isSuperAdmin ? (
                                            <>
                                                <p className="containerES-ticket-content__label">Report Status:</p>
                                                <Form.Group controlId="adminStatusDropdown.change">
                                                    <Form.Select value={ReportApproval} onChange={(e) => setReportApproval(e.currentTarget.value)}>
                                                        <option value={"Awaiting Approval"}>Awaiting Approval</option>
                                                        <option value={"Approved"}>Approved</option>
                                                        <option value={"Approved & Downloadable"}>Approved & Downloadable</option>
                                                        <option value={"Denied"}>Denied</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </>
                                        ) : (
                                            <>
                                                <p className="containerES-ticket-content__label">Report Status:</p>
                                                <Status text={reportRequest.ReportApproval} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>

                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25"}></div>
                        </div>
                        <p className="containerES-ticket-content__label">Description:</p>
                        <div className="containerES-ticket-content-description" dangerouslySetInnerHTML={{ __html: reportRequest?.content || "" }}></div>

                        {(isAdmin || reportRequest.status === "Completed") && (reportRequest.status === "In Progress" || reportRequest.status === "Send For Approval" || reportRequest.status === "Completed") && (
                            <>
                                <p className="containerES-ticket-content__label">Report Data:</p>
                                {reportRequest.ReportApproval === "Approved & Downloadable" &&
                                    <Button onClick={downloadData} className="buttonES mb-2">Download Data</Button>
                                }
                                {file.length < 1 && <FileUploader handleChange={handleChange} name="file" types={fileTypes} />}
                                {file.length > 0 && <DataGrid rows={rows} columns={columns} />}
                            </>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default ReportContainer;
